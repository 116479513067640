// CONTROLS REDUCER || CONTROLS ACTION
export const SET_SEARCH = "SET_SEARCH";
export const SET_SORTBY = "SET_SORTBY";
export const SET_FILTERBY = "SET_FILTERBY";
export const SET_GOSEARCH = "SET_GOSEARCH";
export const ON_ERROR = "ON_ERROR";

// STUDENT REDUCER || STUDENT ACTION
export const SET_LIST = "SET_LIST";
export const SET_TOPSTUDENT = "SET_TOPSTUDENT";
export const SET_VIEWID = "SET_VIEWID";
export const VIEW_ONE_STUDENT = "VIEW_ONE_STUDENT"; 
export const SET_AUTH = "SET_AUTH"; 
export const SET_COMMENTLIST = "SET_COMMENTLIST"; 
export const SHOW_SUBCOMMENTS = "SHOW_SUBCOMMENTS";


